class NiteSelect {
    constructor(selector) {
        if (typeof selector === 'string') {
            if (selector.startsWith('#')) {
                // Handle ID selector
                this.dropdown = document.getElementById(selector.slice(1)); // Select the element by ID

                if (this.dropdown) {
                    this.initializeDropdown(this.dropdown);
                }
            } else if (selector.startsWith('.')) {
                // Handle class selector
                this.dropdowns = document.querySelectorAll(selector); // Select elements by class

                this.dropdowns.forEach((dropdown) => {
                    this.initializeDropdown(dropdown);
                });
            } else {
                throw new Error('Invalid selector. Use either an ID (e.g., "#action-menu") or a class (e.g., ".action-menu").');
            }
        } else if (selector instanceof Element) {
            // Handle direct DOM element
            this.dropdown = selector;
            this.initializeDropdown(this.dropdown);
        } else if (typeof selector === 'function') {
            // Handle function reference
            const element = selector();
            if (element instanceof Element) {
                this.dropdown = element;
                this.initializeDropdown(this.dropdown);
            } else {
                throw new Error('The function must return a valid DOM element.');
            }
        } else {
            throw new Error('Invalid input for NiteSelect constructor');
        }

        this.identifier = this.extractIdentifier(selector);
    }

    /**
     * Common initialization logic for a single dropdown.
     * @param {HTMLElement} [dropdown=this.dropdown] - The dropdown element.
     */
    initializeDropdown(dropdown) {
        this.dropdown = dropdown;
        this.dropdownList = dropdown.querySelector('.dropdown_list');
        this.dropdownIcon = dropdown.querySelector('#dropdown-icon');
        this.clearIcon = dropdown.querySelector('#clear-icon');
        this.dropdownItems = dropdown.querySelectorAll('.dropdown_item');

        // Specifically for MultiSelect
        this.multiSelect = this.dropdown.getAttribute('data-multiselect') === 'true';
        
        if (this.multiSelect) {
            this.inputContainer = this.dropdown.querySelector('#input-container');
            this.searchInputToFocus = this.dropdownList.querySelector('#dropdown_search');
        } else {
            this.dropdownInput = dropdown.querySelector('.dropdown_input');
        }

        this.selectedItemClick();

        this.timeout;

        // Bind event listener context
        this.keyboardNavigation = this.keyboardNavigation.bind(this);

        this.addEventListeners();
    }

    /**
     * Shows the dropdown and adjusts icons accordingly.
     */
    showDropdown() {
        if (this.clearIcon) {
            this.clearIcon.classList.add('hidden');
        }

        if (this.dropdownIcon) {
            this.dropdownIcon.classList.add('rotate-180');
        }

        this.dropdownList.classList.remove('hidden');
        this.dropdownList.classList.add('fade-in-top');

        
        if (this.multiSelect) {
            // const searchInputToFocus = this.dropdownList.querySelector('#dropdown_search');

            if (this.searchInputToFocus) {
                setTimeout(() => {
                    this.searchInputToFocus.focus();
                }, 100); // Adjust the delay time as needed
            }
        }
    }

    /**
     * Hides the dropdown and adjusts icons accordingly.
     */
    hideDropdown() {
        this.dropdownList.classList.add('hidden');

        if (this.dropdownIcon) {
            this.dropdownIcon.classList.remove('rotate-180');
        }
    }

    /**
     * Performs search filtering based on input value.
     */
    search() {
        let filter;
        let searchInput

        if(this.multiSelect) {
            searchInput = this.dropdownList.querySelector('#dropdown_search');
            filter = searchInput.value.toLowerCase();
        } else {
            filter = this.dropdownInput.value.toLowerCase();
        }

        this.dropdownItems.forEach(item => {
            const text = item.textContent.toLowerCase();

            if (text.includes(filter)) {
                item.classList.remove('hidden');
            } else {
                item.classList.add('hidden');
            }
        });
    }

    /**
     * Resets the search by showing all the dropdown items and clearing the search input field.
     */
    resetSearch(inputContainer) {
        this.dropdownItems.forEach(item => {
            item.classList.remove('hidden');
        });
    
        if (this.multiSelect) {
            const searchInput = this.dropdownList.querySelector('#dropdown_search');
            if (searchInput) {
                searchInput.value = ''; // Clear the search input field
            }
        } else {
            this.dropdownInput.value = ''; // Clear the search input field
        }

        if (this.multiSelect) {
            // Check if the focus is still in the inputContainer and focus on the searchInput
            if (document.activeElement === inputContainer) {
                // const searchInputToFocus = this.dropdownList.querySelector('#dropdown_search');
    
                if (this.searchInputToFocus) {
                    setTimeout(() => {
                        this.searchInputToFocus.focus();
                    }, 100); // Adjust the delay time as needed
                }
            }
        }

        // // Check if the focus is still in the inputContainer and focus on the searchInput
        // if (document.activeElement === inputContainer) {
        //     newSearchInput.focus();
        // }
    }

    /**
     * Simulates a click event on the selected dropdown item if [selected] attribute is present
     * on the <li> item and triggers the `populateInputValue()` function.
     * If multi-select is enabled, applies click event for each selected item and calls `populateMultiSelectInput()`.
     * 
     * @returns {void}
     */
    selectedItemClick() {
        const selectedItems = this.dropdownList.querySelectorAll('.dropdown_item[selected]');
        
        if (selectedItems.length > 0) {
            // If multi-select is enabled
            if (this.multiSelect) {
                selectedItems.forEach(selectedItem => {
                    const clickEvent = new Event('click', {
                        bubbles: true,
                        cancelable: true,
                        // You can include additional properties here if needed
                    });
                    selectedItem.dispatchEvent(clickEvent);
                    this.populateMultiSelectInput(clickEvent); // Pass the newly created event object
                });
            } else {
                // For single select, trigger click event on the first selected item
                const clickEvent = new Event('click', {
                    bubbles: true,
                    cancelable: true,
                    // You can include additional properties here if needed
                });
                selectedItems[0].dispatchEvent(clickEvent);
                this.populateInputValue(clickEvent); // Pass the newly created event object
            }
        }
    }
    

    /**
     * Populates the input value and handles data attributes.
     * @param {Event} event - The click event.
     */
    populateInputValue(event) {
        const target = event.target;
        if (event.target.classList.contains('dropdown_item')) {
            const input = event.target.closest('.relative').querySelector('input');
            const oldValue = input.value;
            input.value = event.target.textContent.trim();
            input.setAttribute('value', event.target.textContent.trim());

            this.handleDataAttributes(input, event.target.dataset);

            // // Save the value to localStorage
            // localStorage.setItem(`niteSelectValue_${this.identifier}`, this.dropdownInput.value);

            this.hideDropdown();
            this.showClearIcon();
            this.notification();
        }
    }

    /**
     * Populates input container with selected values for multi-select.
     * @param {Event} event - The click event.
     */
    populateMultiSelectInput(event) {
        const target = event.target;
        if (target.classList.contains('dropdown_item')) {
            const selectedLi = event.target;
            const dataInputs = event.target.dataset;
            const selectedValue = selectedLi.textContent.trim();

            const filteredDataInputs = {};
            Object.keys(dataInputs).forEach(key => {
                if (key.startsWith('chipInput')) {
                    const newKey = key.replace('chipInput', ''); // Remove 'chipInput' prefix
                    filteredDataInputs[newKey.toLowerCase()] = dataInputs[key]; // Store keys in lowercase
                }
            });

            // Constructing data attributes from filteredDataInputs
            let dataAttributes = '';
            Object.keys(filteredDataInputs).forEach(key => {
                dataAttributes += `data-${key.toLowerCase()}="${filteredDataInputs[key]}" `;
            });            

            // Create hidden input fields and append them to the input container's parent node
            const inputContainer = this.inputContainer;
            const chipId = Date.now(); // Generate a unique chip ID
            const hiddenInputs = []; // Define hiddenInputs array
            Object.keys(filteredDataInputs).forEach(attribute => {
                const hiddenInput = document.createElement('input');
                hiddenInput.type = 'hidden';
                hiddenInput.setAttribute('data-chip-id', chipId); // Associate the chip ID with the hidden input
                // hiddenInput.id = attribute;
                hiddenInput.name = `${attribute}[]`;
                hiddenInput.value = filteredDataInputs[attribute];
                inputContainer.parentNode.appendChild(hiddenInput);
                hiddenInputs.push(hiddenInput); // Push hiddenInput to hiddenInputs array
            });

            // Create the chip element using a template
            const chipTemplate = `
                <div data-chip-id="${chipId}" class="chip" ${dataAttributes}>
                    <div class="flex flex-nowrap lg:w-full items-center relative z-10 text-gray-800 bg-white border border-gray-200 rounded-full p-1 m-1 dark:bg-slate-900 dark:border-gray-700 w-40">
                        ${selectedLi.getAttribute('data-chip-icon') ? `
                            <div id="chip-icon" class="size-6 me-1">
                                ${selectedLi.getAttribute('data-chip-icon')}
                            </div>
                        ` : ''}
                        <div id="chip-title" class="whitespace-nowrap mx-2 text-gray-800 dark:text-gray-200 truncate overflow-hidden">
                            ${selectedLi.getAttribute('data-chip-title') || selectedValue}
                        </div>
                        ${selectedLi.getAttribute('data-chip-cancel') === 'true' ? `
                            <div id="chip-cancel" class="chip-cancel ml-2 flex items-center justify-center size-4 p-1 rounded-full text-gray-800 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 text-xs dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-slate-300 cursor-pointer">
                                <svg class="w-4 h-4 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                            </div>
                        ` : ''}
                    </div>
                </div>
            `;

            // Append the chip to the input container
            this.inputContainer.insertAdjacentHTML('beforeend', chipTemplate);

            // Add event listener for chip-cancel for each chip
            const chipCancels = this.inputContainer.querySelectorAll('.chip-cancel');
            chipCancels.forEach((chipCancel, index) => {
                chipCancel.addEventListener('click', () => {
                    const chip = chipCancel.closest('.chip');
                    if (chip) {
                        chip.remove();
                        
                        // Remove the corresponding hidden input using chip's ID
                        const chipId = chip.getAttribute('data-chip-id');
                        const correspondingInputs = hiddenInputs.filter(input => input.getAttribute('data-chip-id') === chipId);
                        correspondingInputs.forEach(input => input.remove());
                    }
                });
            });

        }
    }

    /**
     * Handles the data attributes for a given input element.
     * @param {HTMLInputElement} input - The input element.
     * @param {Object} dataAttributes - The data attributes.
     */
    handleDataAttributes(input, dataAttributes) {
        if (dataAttributes) {

            // Fetching the data-array attribute from the ul element
            const ulElement = this.dropdownList.querySelector('ul');
            const dataArrayAttribute = ulElement ? ulElement.getAttribute('data-array') : null;

            Object.keys(dataAttributes).forEach(attribute => {
                input.setAttribute(`data-${attribute}`, dataAttributes[attribute]);

                const existingHiddenInput = input.parentNode.querySelector(`input[type="hidden"][name^="${attribute}"]`);
                if (existingHiddenInput) {
                    existingHiddenInput.parentNode.removeChild(existingHiddenInput);
                }

                const hiddenInput = document.createElement('input');
                hiddenInput.type = 'hidden';
                hiddenInput.id = attribute;
                hiddenInput.name = dataArrayAttribute ? `${attribute}[]` : attribute;
                hiddenInput.value = dataAttributes[attribute];
                input.parentNode.appendChild(hiddenInput);
            });
        }
    }

    /**
     * Handles the data inputs for a given input container element in a multi-select scenario.
     * @param {HTMLElement} inputContainer - The input container element.
     * @param {Object} dataInputs - The data inputs.
     */
    handleMultiSelectDataInputs(inputContainer, dataInputs) {
        if (dataInputs) {
            const filteredDataInputs = {};
            Object.keys(dataInputs).forEach(key => {
                if (key.startsWith('chipInput')) {
                    filteredDataInputs[key] = dataInputs[key];
                }
            });
        }
    }

    /**
     * Notifies that a selection has been made by creating a custom event.
     */
    notification() {
        // Trigger an event
        const event = new CustomEvent('niteSelectionMade', {
            detail: { identifier: this.identifier },
        });

        if (this.dropdownInput) {
            this.dropdownInput.dispatchEvent(event);
        } else {
            this.dispatchEvent(event); // Dispatch event from the NiteSelect instance
        }

        document.dispatchEvent(event);
    }

    /**
     * Adds an event listener to either the dropdown input element of the NiteSelect instance
     * or the document, depending on whether the dropdown input exists or not.
     * @param {string} eventName - The name of the event to listen for.
     * @param {Function} callback - The function to be executed when the event occurs.
     */
    addNiteEventListener(eventName, callback) {
        // Check if dropdownInput exists
        if (this.dropdownInput) {
            // If dropdownInput exists, add event listener to it
            this.dropdownInput.addEventListener(eventName, callback);
        } else {
            // If dropdownInput does not exist, add event listener to the document
            document.addEventListener(eventName, callback);
        }
    }

    /**
     * Clears the input value and shows the dropdown.
     */
    clearInputValue() {
        this.dropdownInput.value = '';

        this.dropdownItems.forEach(item => {
            item.classList.remove('hidden');
        });

        this.dropdownInput.focus();

        this.showDropdown();
    }

    /**
     * Shows the clear icon.
     */
    showClearIcon() {
        if (this.clearIcon) {
            this.clearIcon.classList.remove('hidden');
        }
    }

    /**
     * Hides the clear icon.
     */
    hideClearIcon() {
        if (this.clearIcon) {
            this.clearIcon.classList.add('hidden');
        }
    }

    /**
     * Hides the dropdown when clicked outside and shows the clear icon if needed.
     * @param {MouseEvent} event - The click event.
     */
    hideDropdownOnClickOutside(event) {
        const isClickInsideDropdown = this.dropdown.contains(event.target);
        let isClickInsideDropdownInput;

        if (this.multiSelect) {
            isClickInsideDropdownInput = this.inputContainer.contains(event.target);
        } else {
            isClickInsideDropdownInput = this.dropdownInput.contains(event.target);
        }
        
        if (!isClickInsideDropdown && !isClickInsideDropdownInput) {
            this.hideDropdown();
        }

        // The clear icon needs to be shown only if it is not a multiselect
        if (!this.multiSelect) {
            // Show clear icon if the dropdownInput has a value
            if (this.dropdownInput.value.trim() !== '') {
                this.showClearIcon();
            }
        }
    }

    /**
     * Extracts the identifier from the selector.
     * @param {string} selector - The selector.
     * @returns {string} The extracted identifier.
     */
    extractIdentifier(selector) {
        if (typeof selector === 'string') {
            // Remove '#' or '.' from the beginning of the selector
            return selector.replace(/^[#.]/, '');
        }
    }

    /**
     * Handles keyboard navigation within a dropdown list.
     * @param {KeyboardEvent} event - The keyboard event triggering the navigation.
     */
    keyboardNavigation(event) {
        const key = event.key;
        const focusedItem = this.dropdownList.querySelector('.focused');

        // console.log('hi'); // For debugging purposes

        if (key === 'ArrowDown' || key === 'ArrowUp') {
            event.preventDefault(); // Prevent page scrolling
            
            let nextItem;
            if (!focusedItem) {
                // If no item is focused, select the first visible item
                nextItem = this.dropdownList.querySelector('li:not(.hidden)');
            } else {
                // Select the next or previous item based on the arrow key pressed
                nextItem = key === 'ArrowDown' ? focusedItem.nextElementSibling : focusedItem.previousElementSibling;
            }
            
            // Ensure the next item exists and is not hidden
            if (nextItem && !nextItem.classList.contains('hidden')) {
                // Remove focus and styling from previously focused item
                if (focusedItem) {
                    focusedItem.classList.remove('focused');
                    focusedItem.classList.remove('bg-gray-100', 'dark:bg-gray-800');
                }
                // Add focus and styling to the next item
                nextItem.classList.add('focused');
                nextItem.classList.add('bg-gray-100', 'dark:bg-gray-800');
                nextItem.focus(); // Set focus to the next item
            }
        } else if (key === 'Enter' && focusedItem) {
            event.preventDefault(); // Prevent form submission or other default behavior
            focusedItem.click(); // Trigger click event on focused item to select it
        }
    }

    /**
     * Adds event listeners to various dropdown elements.
     */
    addEventListeners() {
        
        if(this.multiSelect) {

            this.inputContainer.addEventListener('click', () => this.showDropdown());
            this.inputContainer.addEventListener('focus', () => this.showDropdown());

            this.dropdownList.addEventListener('click', event => this.populateMultiSelectInput(event));

            
            // Search when typing in the input container
            const input = this.dropdownList.querySelector('input[type="text"]');
            input.addEventListener("input", () => {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => this.search(), 200);
            });

            // Add an event listener for the keyboardNavigation
            this.searchInputToFocus.addEventListener('keydown', this.keyboardNavigation);
            // this.dropdownInput.addEventListener('blur', () => this.hideDropdown());
        } else {

            this.dropdownInput.addEventListener('click', () => this.showDropdown());
            this.dropdownInput.addEventListener('focus', () => this.showDropdown());
            
            this.dropdownInput.addEventListener("input", () => {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => this.search(), 200);
            });


            // Add an event listener for the keyboardNavigation
            this.dropdownInput.addEventListener('keydown', this.keyboardNavigation);
            this.dropdownList.addEventListener('click', event => this.populateInputValue(event));

            // this.dropdownInput.addEventListener('blur', () => this.hideDropdown());
        }

        if (this.dropdownIcon) {
            this.dropdownIcon.addEventListener('click', () => this.showDropdown());
        }

        if(this.clearIcon) {
            this.clearIcon.addEventListener('click', () => this.clearInputValue());
        }

        // Add a global click event listener to hide the dropdown when clicked outside
        document.addEventListener('click', event => this.hideDropdownOnClickOutside(event));

        // Add event listener for keyboard navigation

        
    }
}

export default NiteSelect;






// * HTML FORMATS

// ## SINGLE SELECT

/**

// <!-- Single Select --> 
<div id="units" class="relative">
    <div class="relative">
        <input name="unitInput"  
            class="dropdown_input block mt-1 w-full border-gray-300 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-300 focus:border-indigo-500 dark:focus:border-indigo-600 focus:ring-indigo-500 dark:focus:ring-indigo-600 rounded-md shadow-sm"
            type="text" placeholder="Select Unit" />
        <div class="flex flex-row space-x-7">
            <svg id="clear-icon" xmlns="http://www.w3.org/2000/svg"
                class="hidden absolute right-12 top-1/2 transform -translate-y-1/2 transition-transform duration-200 icon icon-tabler icon-tabler-x"
                width="16" height="16" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M18 6l-12 12" />
                <path d="M6 6l12 12" />
            </svg>
            <svg id="dropdown-icon" xmlns="http://www.w3.org/2000/svg"
                class="dropdown_icon absolute right-4 top-1/2 transform -translate-y-1/2 transition-transform duration-200 icon icon-tabler icon-tabler-chevron-down"
                width="16" height="16" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M6 9l6 6l6 -6"></path>
            </svg>
        </div>
    </div>
    <div class="dropdown_list hidden">
        <ul  class="absolute z-10 w-full mt-2 bg-white shadow-md dark:shadow-xl rounded-lg dark:bg-gray-900 dark:border dark:border-gray-700 dark:divide-gray-700">
            <div class="max-h-64 overflow-y-auto">
                @foreach ($units as $unit)
                    <li data-unit_id="{{ $unit->id }}" class="dropdown_item flex items-center gap-x-3.5 py-2 px-3 m-2 rounded-sm text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700">
                        {{ $unit->name }}
                    </li>                                
                @endforeach
            </div>
        </ul>
    </div>
</div>

*/

// ## MULTI SELECT

/**

<!-- Multi Select -->
<div id="mumineen_select" data-multiselect="true" class="relative">
    <div class="relative">
        <div id="input-container" class="bg-green-400 flex relative ps-0.5 pe-9 min-h-[46px] items-center flex-wrap text-nowrap w-full border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
            
        </div>        
        <div class="flex flex-row space-x-7">
            <svg id="dropdown-icon" xmlns="http://www.w3.org/2000/svg"
                class="dropdown_icon absolute right-4 top-1/2 transform -translate-y-1/2 transition-transform duration-200 icon icon-tabler icon-tabler-chevron-down"
                width="16" height="16" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M6 9l6 6l6 -6"></path>
            </svg>
        </div>
    </div>
    <div class="dropdown_list hidden">
        <ul
            class="absolute z-10 w-full mt-2 bg-white shadow-md dark:shadow-xl rounded-lg dark:bg-gray-900 dark:border dark:border-gray-700 dark:divide-gray-700">
            <div class="max-h-64 overflow-y-auto px-3">
                <input id="dropdown_search" type="text" placeholder="Search..." class="my-2 block w-full text-sm border-gray-200 rounded-lg focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 py-2 px-3">
                @foreach ($users as $user)
                    <li class="dropdown_item flex items-center gap-x-3.5 px-3 py-2 m-2 rounded-sm text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700"
                        data-chip-icon='
                            <span class="inline-block rounded-full text-xs px-2 py-1"
                                style="background-color: {{ $user->avatar_bg_color }};">
                                {{ makeImageFromName($user->name, 1) }}
                            </span>'
                        data-chip-title="{{ $user->name }}"
                        data-chip-cancel="true"
                        data-chip-input-user_id="{{ $user->id }}"
                        data-chip-input-user_name="{{ $user->name }}">
                        {{ $user->name }}
                    </li>
                @endforeach

            </div>
        </ul>
    </div>
</div>

*/


































// class niteSelect {
//     constructor(selector) {
//         if (selector.startsWith('#')) {
//             // Handle ID selector
//             this.container = document.getElementById(selector.slice(1)); // Select the element by ID

//             if (this.container) {
//                 this.initializeSelect(this.container);
//             }
//         } else if (selector.startsWith('.')) {
//             // Handle class selector
//             this.containers = document.querySelectorAll(selector); // Select elements by class

//             this.containers.forEach((container) => {
//                 this.initializeSelect(container);
//             });
//         } else {
//             throw new Error('Invalid selector. Use either an ID (e.g., "#nite-select") or a class (e.g., ".nite-select").');
//         }
//     }

//     initializeSelect(container) {
//         // Your existing initialization logic for a single dropdown can go here
//         const input = container.querySelector('.dropdown_input');
//         const list = container.querySelector('.dropdown_list');
//         const icon = container.querySelector('.dropdown_icon');
//         const items = list.querySelectorAll('li');

//         this.selectedItemIndex = -1;
//         this.lastValidValue = '';
        
//         this.setupEventListeners(container, list, items);
//     }


//     handleKeyPress(keyCode, container, list, items) {
//         items.forEach((item, index) => {
//             item.classList.remove('bg-gray-100', 'bg-gray-700');
//         });

//         switch (keyCode) {
//             case 40: // Down arrow
//                 this.selectedItemIndex = this.selectedItemIndex < items.length - 1 ? this.selectedItemIndex + 1 : 0;
//                 break;
//             case 38: // Up arrow
//                 this.selectedItemIndex = this.selectedItemIndex > 0 ? this.selectedItemIndex - 1 : items.length - 1;
//                 break;
//         }

//         items[this.selectedItemIndex].classList.add('bg-gray-100', 'bg-gray-700');
//         items[this.selectedItemIndex].scrollIntoView({
//             behavior: 'auto',
//             block: 'nearest',
//             inline: 'start',
//         });
//     }

//     updateInputValue(newValue) {
//         this.input.value = newValue;
//         this.lastValidValue = newValue;
//     }

//     showSelect(container, list) {
//         list.classList.add("transform", "scale-100", "opacity-100", "transition-ease-in", "duration-75");
//         list.classList.remove("transform", "scale-90", "opacity-0", "transition-ease-out", "duration-100");
//         setTimeout(() => {
//             list.classList.remove("hidden");
//             icon.classList.add('rotate-180');

//             // list.style.transformOrigin = 'top left';

//             // if (container && list) {
//             //     const popperInstance = Popper.createPopper(container, list, {
//             //         placement: 'bottom-start',
//             //         modifiers: [
//             //             {
//             //                 name: 'flip',
//             //                 enabled: true,
//             //             },
//             //             {
//             //                 name: 'offset',
//             //                 options: {
//             //                     offset: [0, 0.5 * parseFloat(getComputedStyle(document.documentElement).fontSize)],
//             //                 },
//             //             },
//             //         ],
//             //     });
//             //     container.popperInstance = popperInstance;
//             // }
//         }, 100);
//     }

//     hideSelect(container, list) {
//         list.classList.add("transform", "scale-90", "opacity-0", "transition-ease-out", "duration-100");
//         setTimeout(() => {
//             list.classList.add("hidden");
//             icon.classList.remove('rotate-180');
//         }, 100);
//     }

//     setupEventListeners(container, list, items) {
//         container.addEventListener('click', (event) => {
//             this.showSelect(container, list);
//         });

//         const input = container.querySelector('.dropdown_input');
//         input.addEventListener('focus', () => {
//             this.showSelect(container, list);
//         });

//         input.addEventListener('blur', () => {
//             setTimeout(() => {
//                 this.hideSelect(container, list);
//                 if (input.value !== this.lastValidValue) {
//                     this.showSelect(container, list);
//                     this.lastValidValue = input.value;
//                     const filter = input.value.toLowerCase();
//                     items.forEach((item) => {
//                         const text = item.textContent.toLowerCase();
//                         if (text.includes(filter)) {
//                             item.classList.remove('hidden');
//                         } else {
//                             item.classList.add('hidden');
//                         }
//                     });
//                 } else {
//                     if (!this.itemsArrayIncludesValue(input.value)) {
//                         this.updateInputValue(input, this.lastValidValue);
//                     }
//                 }
//             }, 200);
//         });

//         input.addEventListener('input', () => {
//             const filter = input.value.toLowerCase();

//             items.forEach((item) => {
//                 const text = item.textContent.toLowerCase();
//                 if (text.includes(filter)) {
//                     item.classList.remove('hidden');
//                 } else {
//                     item.classList.add('hidden');
//                 }
//             });
//         });

//         input.addEventListener('keydown', (event) => {
//             if (event.key === 'ArrowDown' || event.keyCode === 40) {
//                 this.handleKeyPress(40, container, list, items);
//             } else if (event.key === 'ArrowUp' || event.keyCode === 38) {
//                 this.handleKeyPress(38, container, list, items);
//             } else if (event.key === 'Enter' || event.keyCode === 13) {
//                 event.preventDefault();
//                 if (this.selectedItemIndex >= 0) {
//                     input.value = items[this.selectedItemIndex].textContent.trim();
//                     this.hideSelect(container, list);
//                 }
//             }
//         });
        
//         list.addEventListener('click', (event) => {
//             if (event.target.tagName === 'LI') {
//                 input.value = event.target.textContent.trim();
//                 this.hideSelect(container, list);
//             }
//         });
//     }

//     itemsArrayIncludesValue(value) {
//         return Array.from(this.items).some(item => item.textContent.trim() === value);
//     }
// }

// export default niteSelect;
































// * Works as expected. Updating it to initialize it either by an id or class name
// class niteSelect {
//     constructor(containerId) {
//         this.container = document.getElementById(containerId);
//         this.input = this.container.querySelector('.dropdown_input');
//         this.list = this.container.querySelector('.dropdown_list');
//         this.icon = this.container.querySelector('.dropdown_icon');
//         this.items = this.list.querySelectorAll('li');
//         this.selectedItemIndex = -1;
//         this.lastValidValue = '';

//         this.setupEventListeners();
//     }

//     handleKeyPress(keyCode) {
//         this.items.forEach((item, index) => {
//             item.classList.remove('bg-gray-100', 'bg-gray-700');
//         });

//         switch (keyCode) {
//             case 40: // Down arrow
//                 this.selectedItemIndex = this.selectedItemIndex < this.items.length - 1 ? this.selectedItemIndex + 1 : 0;
//                 break;
//             case 38: // Up arrow
//                 this.selectedItemIndex = this.selectedItemIndex > 0 ? this.selectedItemIndex - 1 : this.items.length - 1;
//                 break;
//         }

//         this.items[this.selectedItemIndex].classList.add('bg-gray-100', 'bg-gray-700');
//         this.items[this.selectedItemIndex].scrollIntoView({
//             behavior: 'auto',
//             block: 'nearest',
//             inline: 'start',
//         });
//     }

//     updateInputValue(newValue) {
//         this.input.value = newValue;
//         this.lastValidValue = newValue;
//     }

//     showSelect() {
//         this.list.classList.add("transform", "scale-100", "opacity-100", "transition-ease-in", "duration-75");
//         this.list.classList.remove("transform", "scale-90", "opacity-0", "transition-ease-out", "duration-100");
//         setTimeout(() => {
//             this.list.classList.remove("hidden");
//             this.icon.classList.add('rotate-180');
//         }, 100);
//     }

//     hideSelect() {
//         this.list.classList.add("transform", "scale-90", "opacity-0", "transition-ease-out", "duration-100");
//         setTimeout(() => {
//             this.list.classList.add("hidden");
//             this.icon.classList.remove('rotate-180');
//         }, 100);
//     }

//     setupEventListeners() {
//         this.container.addEventListener('click', (event) => {
//             this.showSelect();
//         });

//         this.input.addEventListener('focus', () => {
//             this.showSelect();
//         });

//         this.input.addEventListener('blur', () => {
//             setTimeout(() => {
//                 this.hideSelect();
//                 if (this.input.value !== this.lastValidValue) {
//                     this.showSelect();
//                     this.lastValidValue = this.input.value;
//                     const filter = this.input.value.toLowerCase();
//                     this.items.forEach((item) => {
//                         const text = item.textContent.toLowerCase();
//                         if (text.includes(filter)) {
//                             item.classList.remove('hidden');
//                         } else {
//                             item.classList.add('hidden');
//                         }
//                     });
//                 } else {
//                     if (!this.itemsArrayIncludesValue(this.input.value)) {
//                         this.updateInputValue(this.lastValidValue);
//                     }
//                 }
//             }, 200);
//         });

//         this.input.addEventListener('input', () => {
//             const filter = this.input.value.toLowerCase();

//             this.items.forEach((item) => {
//                 const text = item.textContent.toLowerCase();
//                 if (text.includes(filter)) {
//                     item.classList.remove('hidden');
//                 } else {
//                     item.classList.add('hidden');
//                 }
//             });
//         });

//         this.input.addEventListener('keydown', (event) => {
//             if (event.key === 'ArrowDown' || event.keyCode === 40) {
//                 this.handleKeyPress(40);
//             } else if (event.key === 'ArrowUp' || event.keyCode === 38) {
//                 this.handleKeyPress(38);
//             } else if (event.key === 'Enter' || event.keyCode === 13) {
//                 event.preventDefault();
//                 if (this.selectedItemIndex >= 0) {
//                     this.input.value = this.items[this.selectedItemIndex].textContent.trim();
//                     this.hideSelect();
//                 }
//             }
//         });

//         this.list.addEventListener('click', (event) => {
//             if (event.target.tagName === 'LI') {
//                 this.input.value = event.target.textContent.trim();
//                 this.hideSelect();
//             }
//         });
//     }

//     itemsArrayIncludesValue(value) {
//         return Array.from(this.items).some(item => item.textContent.trim() === value);
//     }
// }

// export default niteSelect;